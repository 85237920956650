import React, { Component, createElement, Fragment } from 'react';
import { BrowserRouter} from "react-router-dom";

import { observer } from 'mobx-react';
import "bootstrap";

import 'bootstrap/dist/js/bootstrap.min.js';

import "jquery/dist/jquery.min.js";
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "./container/home/Home";

import "../src/global/firebase/index";


import $ from "jquery";
window.jQuery = $;
window.$ = $;


interface AppState { }

interface AppProps { }

export class App extends Component<AppProps, AppState> {
  state: AppState = {};

  componentDidMount() { }
    
  render() {
    return (
      <div className="container-scroller">
        <BrowserRouter>
          <Fragment> 
              <Home />
          </Fragment>
        </BrowserRouter>
      </div>
    );
  }
}


export default observer(App);

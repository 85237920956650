import { Component, createElement, Suspense, lazy  } from "react";
import { RouteComponentProps, Redirect,Switch, Route } from "react-router";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import routes from '../../global/routes';

const NavBar = lazy(() => import('./nav/navbar'));
const Footer = lazy(() => import('./footer/footer'));
const EventList = lazy(() => import('../../pages/EventListing/EventListing'));
const EventComponent = lazy(() => import('../../pages/IndividualEventPage/EventComponent'));
const TermsOfService = lazy(() => import('../../pages/TermsOfService/termsOfService'));
const PrivacyPolicy = lazy(() => import('../../pages/PrivacyPolicy/privacyPolicy'));
const ContactUs = lazy(() => import('../../pages/ContactUs/contactUs'));
const EventDetails = lazy(() => import('../../pages/EventDetails/EventDetails'));
const EventModel = lazy(() => import('../../pages/AddEvent/evetModal'));
const Application = lazy(() => import('../../pages/Application/application'));


export interface HomeProps extends RouteComponentProps { }

class Home extends Component<HomeProps, any> {

  render() {
    return (
      <div>      
        <Suspense fallback={null}>
          <NavBar/>
            <Switch>
              <Route path={routes.EVENTLIST} component={props =><EventList {...props}/>} />
              <Route path={routes.EVENTDETAILS} component={props =><EventDetails {...props}/>} />
              <Route path={`${routes.EVENT}/:id`} component={props =><EventComponent {...props}/>} />
              <Route path={routes.TERMSOFSERVICE} component={props =><TermsOfService {...props}/>} />
              <Route path={routes.PRIVACYPOLICY} component={props =><PrivacyPolicy {...props}/>} />
              <Route path={routes.CONTACTUS} component={props =><ContactUs {...props}/>} />
              <Route path={routes.ADDEVENT} component={props =><EventModel {...props}/>} />
              <Route path={routes.APPLICATION} component={props =><Application {...props}/>} />
              <Redirect to={routes.EVENTLIST} />           
            </Switch>
          <Footer/>
        </Suspense>
      </div>
    );
  }
}

export default  withRouter (observer(Home));

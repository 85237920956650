
const Routes = {

  HOME: "/app",
  EVENTLIST: "/app/events",
  TERMSOFSERVICE:"/app/termsofservice",
  PRIVACYPOLICY:"/app/privacypolicy",
  CONTACTUS: "/app/contactUs",
  EVENTDETAILS: "/app/eventdetails",
  EVENT:"/app/event",
  ADDEVENT:'/app/addevent',
  APPLICATION: '/app/application',

};

export default Routes;
